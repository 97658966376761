<template>
  <v-col
    :class="{
      'cols-12 col-sm-7 col-md-5 col-lg-4': !sizeFull,
      'px-0 pb-2': sizeFull
    }"
  >
    <div
      v-for="(address, idx) in stackAddress"
      :key="idx"
      id="select-wrap"
      :class="{
        'mb-6': (!address.isTouched && !address.value) || address.value,
        'mb-2': address.isTouched && !address.value
      }"
    >
      <Multiselect
        :value="address.value"
        :options="address.options"
        :close-on-select="true"
        :hide-selected="false"
        :preserve-search="false"
        :taggable="address.isTaggable"
        label="label"
        track-by="desc"
        select-label=""
        selected-label=""
        deselect-label=""
        :tag-placeholder="$t('label.addBuilding')"
        :placeholder="$t('label.search')"
        :disabled="loading || load"
        @remove="updatePropsAddress"
        @input="setNewBuilding"
        @select="createRowAddress"
        @tag="createNewBuilding"
        @close="address.isTouched = true"
        :class="{ invalid: address.isTouched && !address.value }"
      >
        <template slot="singleLabel" slot-scope="props">
          <span class="option__label mr-1">
            {{ props.option.nodeTypeName }}
          </span>
          <span class="option__text">{{ props.option.desc }}</span>
        </template>
        <template slot="option" slot-scope="props">
          <v-icon color="privat">mdi-check</v-icon>
          <span class="option__label mr-1">
            {{ props.option.nodeTypeName }}
          </span>
          <span
            class="option__text"
            :title="
              !address.isTaggable && props.option.desc.length > 40
                ? props.option.desc
                : false
            "
          >
            {{ props.option.desc }}
          </span>
          <v-icon color="red darken-3" class="option__close">mdi-close</v-icon>
        </template>
        <template slot="placeholder">{{ address.label }}</template>
      </Multiselect>
      <span class="single__legend" :class="{ hide: address.value }">
        {{ address.label }}
      </span>
      <span
        class="typo__label error--text"
        v-show="address.isTouched && !address.value"
      >
        {{ $t("rules.required") }}
      </span>
      <v-progress-circular
        v-if="!address.value && load"
        indeterminate
        color="privat"
      ></v-progress-circular>
    </div>
    <div id="corpus" v-show="isBuildings">
      <v-text-field
        v-model="modelAd.corpus"
        :label="$t('label.corpus')"
        :disabled="loading"
        color="privat"
        outlined
        dense
        @input="$emit('getAddrId', modelAd)"
      ></v-text-field>
    </div>
  </v-col>
</template>

<script>
import Multiselect from "vue-multiselect";
import { interactionDataAddress } from "@/services/settings.services";
import errorHandler from "@/utils/errorHandler";
import changeTextMultiselect from "@/utils/changeTextMultiselect";

export default {
  name: "BaseSelectAddress",
  props: {
    loading: Boolean,
    validate: Boolean,
    sizeFull: Boolean,
    clear: Boolean,
    loadRegion: Boolean
  },
  data() {
    return {
      load: false,
      url: {
        getArea: "esa/addr/region",
        getChild: "esa/addr/child?id=",
        getBuildings: "esa/addr/buildings?id=",
        getAddressId: "esa/addr/updateWCheck"
      },
      addressId: null,
      modelAd: {
        building: null,
        newBuilding: null,
        corpus: "",
        flat: ""
      },
      allBuilding: [],
      stackIdx: 0,
      stackAddress: [
        {
          stackIdx: 0,
          value: null,
          options: [],
          isTouched: false,
          label: this.$i18n.t("label.area")
        }
      ],
      isBuildings: false,
      mapLabel: {
        2: this.$i18n.t("label.region"),
        3: this.$i18n.t("label.city"),
        4: this.$i18n.t("label.street"),
        7: this.$i18n.t("label.street"),
        8: this.$i18n.t("label.building")
      }
    };
  },
  components: {
    Multiselect
  },
  computed: {
    isInvalid() {
      return this.stackAddress.find(el => el.isTouched);
    }
  },
  methods: {
    getArea() {
      this.load = true;
      interactionDataAddress(this.url.getArea, "GET")
        .then(res => {
          let data = res.data;

          this.stackAddress[this.stackIdx].options = data.map(el => {
            return {
              ...el,
              label: `${el.nodeTypeName} ${el.desc}`,
              step: 0
            };
          });
        })
        .catch(err => {
          let errorCode = errorHandler(err, "other");

          this.$emit("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getArea");
        })
        .finally(() => {
          changeTextMultiselect.call(this);
          this.load = false;
        });
    },
    updatePropsAddress(value) {
      this.stackAddress.splice(value.step + 1);
      this.stackAddress[value.step].value = null;
      this.stackIdx = value.step;
      if (value.nodeTypeId < 8) {
        this.isBuildings = false;
        this.modelAd.corpus = "";
        this.modelAd.flat = "";
      }
      if (value.nodeTypeId <= 3) {
        this.$emit("clearAddrId");
      }
    },
    createRowAddress(value) {
      if (value) {
        let newAddress = {};

        this.updatePropsAddress(value);
        this.stackAddress[value.step].value = value;
        if (value.nodeTypeId <= 8) {
          newAddress = {
            stackIdx: this.stackIdx,
            value: null,
            options: [],
            isTouched: false,
            label: this.mapLabel[value.nodeTypeId]
          };
          if (value.nodeTypeId === 8) {
            this.modelAd.street = value;
            newAddress.isTaggable = true;
          }
          this.stackAddress.push(newAddress);
          this.stackIdx++;
        }
        this.timeoutChangeText = setTimeout(() => {
          changeTextMultiselect.call(this);
        }, 100);
        if (value.nodeTypeId < 8) {
          this.getNextAddress(value);
        }
        if (value.nodeTypeId === 8) {
          this.getBuildings(value);
        }
        if (value.nodeTypeId > 8) {
          this.isBuildings = true;
        }
      }
    },
    getNextAddress(value) {
      if (value) {
        let id = value.code;

        this.load = true;
        interactionDataAddress(`${this.url.getChild}${id}`, "GET")
          .then(res => {
            let data;

            data = res.data.map(el => {
              return {
                ...el,
                label: `${el.nodeTypeName} ${el.desc}`,
                step: el.step ? el.step : this.stackIdx
              };
            });
            this.stackAddress[this.stackIdx].options = data;
          })
          .catch(err => {
            let errorCode = errorHandler(err, "other");

            this.$emit("error", [this.$i18n.t(`error.${errorCode}`)]);
            console.log("catch getNextAddress");
          })
          .finally(() => {
            this.load = false;
          });
      }
    },
    getBuildings(value) {
      if (value) {
        let id = value.code;

        this.load = true;
        interactionDataAddress(`${this.url.getBuildings}${id}`, "GET")
          .then(res => {
            let data;

            data = res.data.map(el => {
              return {
                ...el,
                label: `${el.nodeTypeName} ${el.desc}`,
                step: el.step ? el.step : this.stackIdx
              };
            });
            this.allBuilding = data;
            this.stackAddress[this.stackIdx].options = data;
          })
          .catch(err => {
            let errorCode = errorHandler(err, "other");

            this.$emit("error", [this.$i18n.t(`error.${errorCode}`)]);
            console.log("catch getBuildings");
          })
          .finally(() => {
            this.load = false;
          });
      }
    },
    setNewBuilding(value) {
      if (value) {
        this.modelAd.newBuilding = !this.allBuilding.find(
          el => el.desc === value.desc
        );
        if (!this.modelAd.newBuilding) {
          this.modelAd.building = value;
        }
        if (value.nodeTypeId === 9) {
          this.$emit("getAddrId", this.modelAd);
        }
      } else {
        this.modelAd.building = value;
        this.modelAd.newBuilding = false;
        this.isBuildings = false;
      }
    },
    createNewBuilding(value) {
      let building = {};

      if (typeof value === "string") {
        building = {
          desc: value,
          nodeTypeName: this.$i18n.t("label.building"),
          code: "customBuilding",
          label: `${this.$i18n.t("label.building")} ${value}`,
          step: this.stackIdx
        };
        this.stackAddress[this.stackIdx].options.push(building);
        this.stackAddress[this.stackIdx].value = building;
        this.modelAd.building = building;
        this.modelAd.newBuilding = true;
        this.isBuildings = true;
        this.$emit("getAddrId", this.modelAd);
      }
    }
  },
  watch: {
    validate() {
      this.stackAddress[this.stackIdx].isTouched = true;
    },
    clear() {
      this.modelAd = {
        building: null,
        newBuilding: null,
        corpus: "",
        flat: ""
      };
      this.isBuildings = false;
      this.stackIdx = 0;
      this.stackAddress = [
        {
          stackIdx: 0,
          value: null,
          options: [],
          isTouched: false,
          label: this.$i18n.t("label.area")
        }
      ];
    },
    loadRegion() {
      this.getArea();
    }
  },
  mounted() {
    this.getArea();
  },
  beforeDestroy() {
    clearTimeout(this.timeoutChangeText);
  }
};
</script>

<style lang="scss">
#select-wrap {
  position: relative;
  ol,
  ul {
    padding-left: 0;
  }
  .v-progress-circular {
    width: 25px !important;
    height: 25px !important;
    left: inherit;
    right: 0 !important;
  }
}
</style>
